.github-profile {
	margin: 1rem;
}

.github-profile img {
  width: 75px;
}
.github-profile .info {
  display: inline-block;
  margin-left: 12px;
  .name {
    font-size: 1.25rem;
    font-weight: bold;
  }
}

form {
	border: thin solid #888;
  padding: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}

.header {
	text-align: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}